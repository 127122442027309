import { ChevronRightIcon } from '@heroicons/react/outline';
import Link from 'next/link'
import Image from 'next/image';

import React from 'react';
import VgTeam from '../../assets/images/aboutus.png';

function WeAre() {
    return (
        <div className='bg-no-repeat bg-contain bg-right flex flex-wrap max-w-7xl mx-auto pt-[10rem] md:pt-[12rem]'>
            <div className='sm:basis-6/12 sm:px-4 sm:order-2 md:self-center'>
                <h1 className='md:text-header text-header-mbl font-sourcesans relative text-center md:text-left'>
                    <span className="text-secondary-500 after:content-[''] after:absolute after:w-24 md:after:w-32 after:h-1 md:after:h-2 after:top-[50px] md:after:top-[65px] after:left-[40%] md:after:left-0 after:border-4 md:after:border-4 after:border-secondary-500 after:rounded">
                        <span className="text-primary-500 mr-2">Who</span>
                        <span className="text-orange">we are ?</span>
                    </span>
                </h1>

                <div className='w-full mt-10 p-6 md:hidden'>
                    <Image  className='w-full md:w-auto' src={VgTeam} alt="Virtual-Gravity-Team" />
                </div>

                <p className='text-primary-500 md:text-body text-body-mbl mt-10'>{`Virtual Gravity is a design and development company that specializes in building small and enterprise level software for small to medium size businesses. Based out of Sunshine, Victoria, our team of developers can leverage their years of experience with top technologies and frameworks to help you achieve your goals.`}</p>
                <Link href={`/about`} passHref>
                    <button
                    className="whitespace-nowrap inline-flex items-center justify-center px-12 py-3 border border-transparent rounded-full shadow-sm text-h3 font-montserratB text-white bg-btn-gradient mt-8"
                    >
                    {`MORE`} &nbsp;
                    <ChevronRightIcon
                        className="h-4 w-4"
                        style={{ strokeWidth: "3px" }}
                        aria-hidden="true"
                    />
                    </button>
                </Link>
            </div>
            <div className='sm:px-4 sm:basis-6/12 mt-16 md:mt-auto hidden md:flex'>
                <div className='flex gap-5 sm:flex-col flex-wrap mr-16'>
                    <Image  className='w-full md:w-auto' src={VgTeam} alt="Virtual-Gravity-Team" />
                </div>
            </div>
        </div>
    )
}

export default WeAre;